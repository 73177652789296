<template>
    <div v-if="modal" class="modal" :style="cssProps">
        <q-icon 
            v-if="!minimized && hideable"
            name="fas fa-angle-double-down" 
            size="2rem" 
            color="dark_grey"
            class="minimize-button"
            tabindex="0"
            @click="minimized = !minimized"
        >
            <q-tooltip v-html="resource[lang].hide" />
        </q-icon>
        <q-icon 
            v-if="minimized && hideable"
            name="fas fa-angle-double-up" 
            size="2rem" 
            color="dark_grey"
            class="minimize-button"
            tabindex="0"
            @click="minimized = !minimized"
        >
            <q-tooltip v-html="resource[lang].show" />
        </q-icon>
        <q-icon 
            name="fas fa-times" 
            size="2rem" 
            color="dark_grey"
            class="close-button"
            tabindex="0"
            @click="close"
        >
            <q-tooltip v-html="resource[lang].close" />
        </q-icon>
        <slot />
    </div>
</template>

<script>
import resource from '@/assets/resources/base-modal-resource'

import { mapGetters } from 'vuex'

export default {
    props: {
        wide: {
            type: Boolean,
            default: false,
        },
        hideable: {
            type: Boolean,
            default: false,
        }
    },
    data() {
        const lang = localStorage.getItem('lang') || 'en';

        return {
            lang: lang,

            minimized: false,
            scroll: 0,

            resource
        }
    },
    computed: {
        ...mapGetters(['modal']),
        cssProps() {
            if (this.wide) {
                return {
                    '--width': '100rem',
                }
            } else {
                return {
                    '--width': '50rem',
                }
            }
        }
    },
    methods: {
        closeModal() {
            this.$store.dispatch('modal', false);
        },
        close() {
            this.$store.dispatch('modal', false);
            this.$emit('close');
        }
    },
    watch: {
        minimized: function(val) {
            if (val) {
                document.querySelector('.modal').classList.add('minimized-modal');
                this.scroll = document.querySelector('.modal').scrollTop;
                document.querySelector('.modal').scrollTop = 0;
            } else {
                document.querySelector('.modal').classList.remove('minimized-modal');
                document.querySelector('.modal').scrollTop = this.scroll;
            }
        }
    },
    emits: ['close']
}
</script>

<style lang="scss" scoped>

.close-button,
.minimize-button {
    position: absolute;
    top: 2rem;
    right: 2rem;
    z-index: 101;

    cursor: pointer;

    @media (max-width: 40rem) {
        top: 1rem;
        right: 1rem;
    }
}

.close-button:hover,
.minimize-button:hover {
    color: var(--black) !important;
}

.minimize-button {
    right: 4rem;
}

.modal {
    position: fixed;

    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    
    background-color: var(--light_grey);

    width: 100%;
    max-width: var(--width);
    height: auto;
    max-height: 80vh;
    
    padding: 2rem;

    overflow-y: auto;
    z-index: 100;

    transition: all 300ms ease-in-out;

    @media (max-width: 40rem) {
        padding: 1rem;
    }
}

.minimized-modal {
    transform: translate(-50%, 50%);
}

</style>
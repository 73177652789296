const resource = {
    en: {
        close: 'Close Window (Modal)',
        hide: 'Hide Window (Modal)',
        show: 'Show Window (Modal)'
    },
    de: {
        close: 'Fenster (Modal) schließen',
        hide: 'Fenster (Modal) verstecken',
        show: 'Fenster (Modal) zeigen'
    }
}

export default resource;
const resource = {
    en: {
        comment: 'comment field',
        commented: 'comments available on this field',
        unresolved: 'unresolved comments available',
        show_help: 'Show Help',
    },
    de: {
        comment: 'Hinterlasse einen Kommentar',
        commented: 'Kommentare auf diesem Feld verfügbar',
        unresolved: 'Noch nicht gelöste Kommentare verfügbar',
        show_help: 'Hilfe anzeigen',
    }
}

export default resource;